import React, { createContext, useContext, useEffect, useState } from 'react'

export interface IContext {
    //isDisabled: boolean
    //setIsdesable: (e: boolean) => void
    stepperr: any
    setStepperr({ })
}
const StepperContext = createContext<IContext>(null)

export const StepperProvider = ({ children }) => {
    const [stepperr, setStepperr] = useState({})

    return (
        <StepperContext.Provider
            value={{
                stepperr,
                setStepperr
            }}
        >
            {children}
        </StepperContext.Provider>
    )
}

export const useStepperContext = () => {
    const context = useContext(StepperContext)
    if (!context) {
        throw new Error('useGlobalContext must be used within a StoreProvider')
    }
    return context
}
