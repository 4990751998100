import { createContext, useContext, useEffect, useState } from "react"
import { StepSelectPowerToRepresentTheCompanyInterface } from "utility/interfaces/step-select-power-to-represent-the-company"
import { FormTypePassWordAndPhoneInputs } from "views/create-account/creat-account-wallet/fist-steps-access/to-manager-create-account/inputs"
import { IProjectTypeInterface } from "views/tokenization/steps/step-contracts/confirmation-brownfield"
import { IregisterEnrollment } from "views/tokenization/steps/step-registration-enrollment/step/step-one-register-enrollment"
export interface IContext {
  isDisabled: boolean
  setIsdesable: (e: boolean) => void
  choosePerson: string
  setChoosePerson(string)
  formTypePassWordAndPhone: FormTypePassWordAndPhoneInputs
  setFormPasswordAndPhone: (string: FormTypePassWordAndPhoneInputs) => void
  selectPowerToRepresentTheCompanyInterface: StepSelectPowerToRepresentTheCompanyInterface
  setselectPowerToRepresentTheCompanyInterface: (
    string: StepSelectPowerToRepresentTheCompanyInterface
  ) => void
  selectProjectType: IProjectTypeInterface
  setSelectProjectType: (string: IProjectTypeInterface) => void
  indicateAPropertyForRealGuarantee: IregisterEnrollment
  setIndicateAPropertyForRealGuarantee: (string: IregisterEnrollment) => void
}
const Context = createContext<IContext>(null)

export const ContextProvider = ({ children }) => {
  const [isDisabled, setIsdesable] = useState(true)
  const [choosePerson, setChoosePerson] = useState<"farm_owner" | "trust_fund" | "industry" | "provider">("industry")

  useEffect(() => {
    const confirmedLocal = localStorage.getItem("salveLocalStorage")
    if (confirmedLocal !== undefined && confirmedLocal === "false") {
      setIsdesable(false)
    } else {
      setIsdesable(true)
    }
  }, [isDisabled])

  const [formTypePassWordAndPhone, setFormPasswordAndPhone] =
    useState<FormTypePassWordAndPhoneInputs>({
      password: "",
      phone: "",
    })

  const [
    selectPowerToRepresentTheCompanyInterface,
    setselectPowerToRepresentTheCompanyInterface,
  ] = useState<StepSelectPowerToRepresentTheCompanyInterface>({
    has_power: "administrator",
  })

  const [selectProjectType, setSelectProjectType] =
    useState<IProjectTypeInterface>({
      plantation_type: "greenfield",
      tree_type: "eucalipto",
      land_type: "terra_propria",
    })

  const [
    indicateAPropertyForRealGuarantee,
    setIndicateAPropertyForRealGuarantee,
  ] = useState<IregisterEnrollment>({
    register_enrollment: "no",
  })

  return (
    <Context.Provider
      value={{
        isDisabled,
        setIsdesable,
        choosePerson,
        setChoosePerson,
        formTypePassWordAndPhone,
        setFormPasswordAndPhone,
        selectPowerToRepresentTheCompanyInterface,
        setselectPowerToRepresentTheCompanyInterface,
        selectProjectType,
        setSelectProjectType,
        indicateAPropertyForRealGuarantee,
        setIndicateAPropertyForRealGuarantee,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error("useGlobalContext must be used within a StoreProvider")
  }
  return context
}
