import React, { createContext, useState, useContext, ReactNode, FC } from 'react'

type ContextType = {
  isSignedRegisterContract: boolean;
  setIsSignedRegisterContract: React.Dispatch<React.SetStateAction<boolean>>
}

const RegistrationContractContext = createContext<ContextType | undefined>(undefined)

export const RegistrationContractProvider = ({ children }) => {
  const [isSignedRegisterContract, setIsSignedRegisterContract] = useState(false)

  return (
    <RegistrationContractContext.Provider
      value={{ isSignedRegisterContract, setIsSignedRegisterContract }}>
      {children}
    </RegistrationContractContext.Provider>
  )
}

export const useRegistrationContractContext = (): ContextType => {
  const context = useContext(RegistrationContractContext)
  if (!context) {
    throw new Error('useRegistrationContractContext must be used within a RegistrationContractProvider')
  }
  return context
}
