import React, { createContext, useContext, useEffect, useState } from 'react'

export interface IContext {
    isDisabled: boolean
    setIsdesable: (e: boolean) => void
    chooseRoom: string
    setChooseRoom(string)
    chooseContractType : string
    setChooseContractType(string)
}
const Context = createContext<IContext>(null)

export const ContextProviderRoom = ({ children }) => {
    const [isDisabled, setIsdesable] = useState(true)
    const [chooseRoom, setChooseRoom] = useState<'privateRoom' | 'publicRoom'>('privateRoom')
    const [chooseContractType, setChooseContractType] = useState<'tpr' | 'tprf'>('tpr') 
    useEffect(() => {
        const confirmedLocal = localStorage.getItem('salveLocalStorage')
        if (confirmedLocal !== undefined && confirmedLocal === 'false') {
            setIsdesable(false)
        } else {
            setIsdesable(true)
        }
    }, [isDisabled])

    return (
        <Context.Provider
            value={{
                isDisabled,
                setIsdesable,
                chooseRoom,
                setChooseRoom,
                chooseContractType,
                setChooseContractType
            }}
        >
            {children}
        </Context.Provider>
    )
}

export const useRoomContext = () => {
    const context = useContext(Context)
    if (!context) {
        throw new Error('useRoomContext must be used within a StoreProvider')
    }
    return context
}
