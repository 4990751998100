import React, { useState } from "react"
const Context = React.createContext(null)
function Provider({ children }) {
  const [auth, setAuth] = React.useState({
    access_token: "",
    refresh_token: "",
  })
  const [profile, setProfile] = React.useState({
    email: "",
    photo: "",
    name: "",
    register_step: "",
    type: "contact",
    platformRole: "",
  })

  const [trigger, setTrigger] = React.useState<boolean>(false)
  const [isContextAvailable, setContextAvailability] =
    React.useState<boolean>(false)

  const execute = React.useCallback(() => {
    const unparsedUser = localStorage.getItem("userSession")
    if (unparsedUser) {
      const { tokens, info } = JSON.parse(unparsedUser)
      setAuth(tokens)
      setProfile(info)
      setContextAvailability(true)
    } else {
      setContextAvailability(false)
      const contact = JSON.parse(localStorage.getItem("contactSesssion"))
      setAuth(contact)
    }
  }, [])

  const clearContext = React.useCallback(() => {
    setAuth({ access_token: "", refresh_token: "" })
    setProfile({
      email: "",
      photo: "",
      name: "",
      register_step: "",
      type: "",
      platformRole: "",
    })
    setContextAvailability(false)
    localStorage.removeItem("userSession")
    //await apiLogout()
  }, [])

  React.useEffect(() => {
    execute()
  }, [trigger])

  return (
    <Context.Provider
      value={{
        auth,
        profile,
        isContextAvailable,
        setContextAvailability,
        setTrigger,
        clearContext,
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default { Context, Provider }
